import React from "react-router-dom";
import ProfileEditForm from "../organisms/ProfileEditForm";

const ProfileEdit = () => {
  return (
    <ProfileEditForm />
  );
}

export default ProfileEdit;
